export const fonts = {
  text: {
    family: 'var(--kindest-font-sans)',
    regular: 300,
    medium: 400,
    large: 500,
  },
  heading: {
    family: 'var(--kindest-font-sans)',
    weight: 600,
  },
}
