import { env } from './env'

const dashboard = {
  redirect: '/dashboard',
  account: '/dashboard/account',

  kindestAdmin: {
    workspaces: '/dashboard/workspaces',
    demo: '/dashboard/demo',
    salesReport: '/dashboard/sales',
    financeReport: '/dashboard/finance',
    customersReport: '/dashboard/customers',
  },

  workspace: (id: string) => ({
    href: '/dashboard/[workspace]',
    as: `/dashboard/${id}`,
  }),

  pages: '/dashboard/[workspace]/pages',
  pageDetail: (id: string, workspaceId = '[workspace]') => ({
    href: '/dashboard/[workspace]/pages/[id]',
    as: `/dashboard/${workspaceId}/pages/${id}`,
  }),

  automations: '/dashboard/[workspace]/automations',
  automationsDetail: (slug: string, workspaceId = '[workspace]') => ({
    href: '/dashboard/[workspace]/automations/[slug]',
    as: `/dashboard/${workspaceId}/automations/${slug}`,
  }),

  campaigns: '/dashboard/[workspace]/campaigns',
  campaignDetail: (id: string, workspaceId = '[workspace]') => ({
    href: '/dashboard/[workspace]/campaigns/[id]',
    as: `/dashboard/${workspaceId}/campaigns/${id}`,
  }),

  textToDonate: '/dashboard/[workspace]/text-to-donate',

  analytics: '/dashboard/[workspace]/analytics',

  reply: '/dashboard/[workspace]/reply',

  institutions: '/dashboard/[workspace]/institutions',
  institutionsDetail: (id: string, workspaceId = '[workspace]') => ({
    href: '/dashboard/[workspace]/institutions/[id]',
    as: `/dashboard/${workspaceId}/institutions/${id}`,
  }),
  institutionsEdit: (id: string, workspaceId = '[workspace]') => ({
    href: '/dashboard/[workspace]/institutions/[id]/edit',
    as: `/dashboard/${workspaceId}/institutions/${id}/edit`,
  }),
  institutionsNew: '/dashboard/[workspace]/institutions/new',

  contacts: '/dashboard/[workspace]/contacts',
  contactsDetail: (id: string, workspaceId = '[workspace]') => ({
    href: '/dashboard/[workspace]/contacts/[id]',
    as: `/dashboard/${workspaceId}/contacts/${id}`,
  }),
  contactsEdit: (id: string, workspaceId = '[workspace]') => ({
    href: '/dashboard/[workspace]/contacts/[id]/edit',
    as: `/dashboard/${workspaceId}/contacts/${id}/edit`,
  }),
  contactsNew: '/dashboard/[workspace]/contacts/new',
  contactsImport: '/dashboard/[workspace]/contacts/import',
  segments: '/dashboard/[workspace]/contacts/segments',

  transactions: '/dashboard/[workspace]/transactions',
  transactionsDetail: (id: string, workspaceId = '[workspace]') => ({
    href: '/dashboard/[workspace]/transactions/[id]',
    as: `/dashboard/${workspaceId}/transactions/${id}`,
  }),
  transactionsEdit: (id: string, workspaceId = '[workspace]') => ({
    href: '/dashboard/[workspace]/transactions/[id]/edit',
    as: `/dashboard/${workspaceId}/transactions/${id}/edit`,
  }),
  transactionsNew: '/dashboard/[workspace]/transactions/new',

  settings: {
    root: '/dashboard/[workspace]/settings',
    members: '/dashboard/[workspace]/settings/members',
    customDomain: '/dashboard/[workspace]/settings/custom-domain',
    donationPage: '/dashboard/[workspace]/settings/donation-page',
    billing: '/dashboard/[workspace]/settings/billing',
    stripe: '/dashboard/[workspace]/settings/payouts',
    billingPlan: '/dashboard/[workspace]/settings/plan',
    donateButton: '/dashboard/[workspace]/settings/donate-button',
    embeddableDonationForm: '/dashboard/[workspace]/settings/embeddable-donation-form',
    emailCapture: '/dashboard/[workspace]/settings/email-capture',
    phoneNumbers: '/dashboard/[workspace]/settings/phone-numbers',
    senderProfile: '/dashboard/[workspace]/settings/sender-profile',
    tags: '/dashboard/[workspace]/settings/tags',
    allocations: '/dashboard/[workspace]/settings/allocations',
    utm: '/dashboard/[workspace]/settings/campaign-tracker',
  },

  integrations: {
    mailchimp: {
      index: '/dashboard/[workspace]/integrations/mailchimp',
      audience: {
        createOrSelect: '/dashboard/[workspace]/integrations/mailchimp/audience',
        create: '/dashboard/[workspace]/integrations/mailchimp/audience/create',
      }
    },
    constantContact: '/dashboard/[workspace]/integrations/constant-contact',
    constantContactList:
      '/dashboard/[workspace]/integrations/constant-contact/list',
    googleAnalytics: '/dashboard/[workspace]/integrations/google-analytics',
    googleAds: '/dashboard/[workspace]/integrations/google-ads',
    googleTagManager: '/dashboard/[workspace]/integrations/google-tag-manager',
    facebookPixel: '/dashboard/[workspace]/integrations/facebook-pixel',
    facebookPage: '/dashboard/[workspace]/integrations/facebook-page',
    salesforce: '/dashboard/[workspace]/integrations/salesforce',
    doubleTheDonation:
      '/dashboard/[workspace]/integrations/double-the-donation',
    doubleTheDonationSetup:
      '/dashboard/[workspace]/integrations/double-the-donation/setup',
  },
}

export const routes = {
  home: '/',
  faq: '/faq',
  pricing: '/pricing',
  signIn: '/sign-in',
  signUp: '/sign-up',
  workspaceSignUp: {
    account: '/sign-up/workspace/account',
    plans: '/sign-up/workspace/plans',
    billing: '/sign-up/workspace/billing',
    checkout: '/sign-up/workspace/checkout',
  },
  signUpSetup: '/sign-up/setup',
  features: {
    all: '/features',
    fundraising: '/features/fundraising',
    automations: '/features/automations',
    crm: '/features/crm',
  },
  integrations: {
    all: '/integrations',
    mailchimp: '/integrations/mailchimp',
    facebookPixel: '/integrations/facebook-pixel',
    facebookPages: '/integrations/facebook-pages',
    doubleTheDonation: '/integrations/double-the-donation',
    googleAds: '/integrations/google-ads',
    googleAnalytics: '/integrations/google-analytics',
    stripe: '/integrations/stripe',
    constantContact: '/integrations/constant-contact',
    salesforce: '/integrations/salesforce',
    dafPayByChariot: 'integrations/dafpay-by-chariot',
  },
  donorPortal: {
    home: '/donor-portal',
    signIn: '/donor-portal/sign-in',
    authorize: '/donor-portal/authorize',
  },
  forgotPassword: '/password/forgot',
  resetPassword: '/password/reset',
  acceptInvitation: '/accept-invitation',
  acceptWorkspaceInvitation: '/accept-workspace',
  ourCommitment: '/our-commitment',
  dashboard,
  oauth: {
    constantContact: '/oauth/constant-contact',
    stripe: '/oauth/stripe',
    salesforce: '/oauth/salesforce',
  },
  legal: {
    donor: {
      faq: '/faq',
      support: '/support',
    },
    terms: '/terms',
    privacyPolicy: '/privacy',
  },
  upgrade: '/upgrade',
  fundraisingPage: {
    home: (slug: string, fundraiserId?: string) => ({
      href: fundraiserId ? `/[slug]/${fundraiserId}` : '/[slug]',
      as: fundraiserId ? `/${slug}/${fundraiserId}` : `/${slug}`,
    }),
    checkout: (slug: string, fundraiserId?: string) => ({
      href: fundraiserId ? `/[slug]/${fundraiserId}/donate` : '/[slug]/donate',
      as: fundraiserId ? `/${slug}/${fundraiserId}/donate` : `/${slug}/donate`,
    }),
  },
  donationPage: (slug: string) => ({
    href: '/[slug]',
    as: `/${slug}`,
  }),
  embeddedFormPage: (slug: string) => ({
    href: '/[slug]/embedded-form',
    as: `/${slug}/embedded-form`,
  }),
  facebookConnect: '/facebook-connect',
  donateUrl: (slug: string, domain: Maybe<string>) =>
    `${domain ? `https://${domain}` : env.baseUrl}/${slug}`,
  stripe: {
    checkoutRedirectUrl: (workspaceId: string) => `${env.baseUrl}${dashboard.settings.billingPlan.replace('[workspace]', workspaceId)}`, // prettier-ignore
  },
  downloadTransactionReceipt: (webId: string) =>
    `${env.apiUrl}/rest/transactions/${webId}/generate-receipt`,

  bookDemo: 'https://meetings.hubspot.com/misty33'
  // bookDemo: 'https://meetings.hubspot.com/denise-wang-kline/sales',
}
