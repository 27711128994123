import * as Dialog from '@radix-ui/react-dialog'
import styled from 'styled-components'
import { colors } from '~/styles/colors'

export const Content = styled(Dialog.Content)({
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  background: 'white',
  border: colors.border,
  borderRadius: 4,
  width: '100%',
  maxWidth: 776,
})

export const Container = styled('div')({
  overflowY: 'scroll',
  display: 'flex',
  flexDirection: 'column',
  minHeight: 0,
  maxHeight: '70vh',
})

export const Header = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

export const Body = styled('div')({
  flex: 1,
})

export const Footer = styled('div')({
  flexShrink: 0,
  padding: 24,
  display: 'grid',
  gridColumnGap: 16,
  gridAutoFlow: 'column',
  justifyContent: 'flex-end',
  borderTop: colors.border,
})

export const Close = styled(Dialog.Close)`
  display: 'inline-flex',
  border-radius: 4,
  outline: 'none',

  '&:focus': {
    ring: 2,
  },
  `

export const Title = styled(Dialog.Title)({
  fontSize: '0.75rem',
})

export const Description = styled(Dialog.Description)({})
