import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { load, trackPageview } from 'fathom-client'
import { env } from '~/config/env'

export const useFathomAnalytics = () => {
  const router = useRouter()

  useEffect(() => {
    if (!env.fathomAnalytics.siteId) {
      return
    }

    load(env.fathomAnalytics.siteId, {
      includedDomains: ['kindest.com'],
    })

    const onRouteChangeComplete = () => {
      trackPageview()
    }
    // Record a pageview when route changes
    router.events.on('routeChangeComplete', onRouteChangeComplete)

    // Unassign event listener
    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete)
    }
  }, [])
}
