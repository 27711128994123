import IconInfo from './assets/icon-info.svg'
import IconSuccess from './assets/icon-success.svg'
import IconWarning from './assets/icon-warning.svg'
import IconError from './assets/icon-error.svg'
import IconClose from './assets/icon-close.svg'
import { Wrapper, CloseButton } from './style'
import type { Variants } from './style'

type Props = {
  variant: Variants
  text: string
  onClose: () => void
}

export const Toast = ({ text, variant, onClose }: Props) => {
  const icons: { [key in Variants]: React.ReactNode } = {
    info: <IconInfo />,
    success: <IconSuccess />,
    warning: <IconWarning />,
    error: <IconError />,
  }

  return (
    <Wrapper
      variant={variant}
      initial={{ bottom: -56 }}
      animate={{ bottom: 30 }}
      exit={{ bottom: -56 }}
    >
      {icons[variant]}
      {text}
      <CloseButton type="button" onClick={onClose}>
        <IconClose />
      </CloseButton>
    </Wrapper>
  )
}

export type ToastVariants = Variants
