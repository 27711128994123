import { setUser as setSentryUser } from '@sentry/react'
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import { useCurrentUserQuery } from '~/graphql/current-user'
import { useSignOutUser } from '~/hooks/use-sign-out-user'
import { CurrentUser } from '~/types'
import { accessToken } from '~/utils/access-token'

type Context = {
  user?: CurrentUser
}

const AuthContext = createContext<Context | undefined>(undefined)

export const useAuth = (): Context => {
  const context = useContext(AuthContext)

  if (!context) {
    throw new Error('useAuth must be used within AuthProvider')
  }

  return context
}

type Props = {
  children?: ReactNode
}

export const AuthProvider: FC<Props> = ({ children }) => {
  const signOutUser = useSignOutUser()

  const { data } = useCurrentUserQuery({
    ssr: false,
    skip: !accessToken.get('dashboard'),
    onError: () => signOutUser(),
  })

  const value = useMemo<Context>(
    () => ({
      user: data?.currentUser,
    }),
    [data]
  )

  useEffect(() => {
    if (data?.currentUser) {
      setSentryUser({
        id: data?.currentUser.id,
        email: data?.currentUser.email,
      })
    }
  }, [data])

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
