import { AnimatePresence } from 'framer-motion'
import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { Portal } from 'reakit/Portal'
import { Toast, ToastVariants } from '~/components/toast'

type Context = {
  info: (text: string) => void
  success: (text: string) => void
  warning: (text: string) => void
  error: (text: string) => void
}

const ToastsContext = createContext<Context | undefined>(undefined)

export const useToasts = (): Context => {
  const context = useContext(ToastsContext)

  if (!context) {
    throw new Error('useAuth must be used within ToastProvider')
  }

  return context
}

type Props = {
  children?: ReactNode
}

export const ToastsProvider: FC<Props> = ({ children }) => {
  const [toast, setToast] =
    useState<{
      variant: ToastVariants
      text: string
    }>()

  const info = useCallback(
    (text: string) => setToast({ variant: 'info', text }),
    []
  )

  const success = useCallback(
    (text: string) => setToast({ variant: 'success', text }),
    []
  )

  const warning = useCallback(
    (text: string) => setToast({ variant: 'warning', text }),
    []
  )

  const error = useCallback(
    (text: string) => setToast({ variant: 'error', text }),
    []
  )

  const value = useMemo(
    () => ({
      info,
      success,
      warning,
      error,
    }),
    [info, success, warning, error]
  )

  return (
    <ToastsContext.Provider value={value}>
      {children}
      <Portal>
        <AnimatePresence>
          {toast && (
            <Toast
              variant={toast.variant}
              text={toast.text}
              onClose={() => setToast(undefined)}
            />
          )}
        </AnimatePresence>
      </Portal>
    </ToastsContext.Provider>
  )
}
