import { useRouter } from 'next/router'

import { routes } from '~/config/routes'
import { useApolloClient } from '@apollo/client'
import { setUser as setSentryUser } from '@sentry/react'
import { AccessType } from '~/types'
import { accessToken } from '~/utils/access-token'

export const useSignOutUser = (type: AccessType = 'dashboard') => {
  const router = useRouter()
  const apollo = useApolloClient()

  return async (to: string | null = routes.signIn) => {
    accessToken.remove(type)

    // redirect user
    if (to) {
      await router.push(to)
    }

    // clear store
    await apollo.stop()
    await apollo.clearStore()

    // unset Sentry user
    setSentryUser(null)
  }
}
