import { WidthProps } from 'styled-system'

import { Link, LinkProps } from '../link'
import { Loading } from './components/loading'
import { Btn, Sizes, Variants } from './style'

type ButtonProps = {
  type?: 'button' | 'submit' | 'reset'
  onClick?: () => void
  loading?: boolean
}

export type Props = WidthProps & {
  children: React.ReactNode
  variant?: Variants
  size?: Sizes
  disabled?: boolean
  className?: string
} & (ButtonProps | LinkProps)

export const Button = (props: Props) => {
  const { children, variant, size, disabled, width, className, ...rest } = props

  if ('href' in rest) {
    return (
      <Link {...rest}>
        <Btn
          as="a"
          variant={variant}
          size={size}
          className={className}
          data-disabled={disabled}
          width={width}
          onClick={rest.onClick}
        >
          {children}
        </Btn>
      </Link>
    )
  }

  const { type, loading, onClick } = rest

  return (
    <Btn
      type={type ?? 'button'}
      variant={variant}
      size={size}
      $loading={loading}
      className={className}
      disabled={disabled}
      onClick={onClick}
      width={width}
    >
      {loading && <Loading />}
      <span style={{ visibility: loading ? 'hidden' : undefined }}>
        {children}
      </span>
    </Btn>
  )
}
