import styled from 'styled-components'
import { variant, width, WidthProps } from 'styled-system'
import { colors } from '~/styles/colors'
import { fonts } from '~/styles/fonts'

import type { CSSObject } from 'styled-components'
export type Variants = 'primary' | 'outline' | 'ghost' | 'danger' | 'light'
export type Sizes = 'default' | 'small' | 'large'

type Props = WidthProps & {
  $loading?: boolean
  variant?: Variants
  size?: Sizes
}

export const Btn = styled.button<Props>(
  {
    display: 'inline-block',
    position: 'relative',
    borderRadius: '2px',
    fontWeight: fonts.text.medium,
    fontSize: '0.875rem',
    textAlign: 'center',
    padding: '0 32px',
    transition: 'all 0.2s ease 0s',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
  },

  variant<CSSObject, Sizes>({
    prop: 'size',
    variants: {
      default: {
        height: 48,
        lineHeight: '3rem',
      },
      small: {
        height: 40,
        lineHeight: '2.5rem',
        padding: '0 20px',
      },
      large: {
        height: 56,
        lineHeight: '3.5rem',
      },
    },
  }),
  variant<CSSObject, Variants>({
    variants: {
      primary: {
        color: colors.white,
        background: colors.primary.default,

        '&:hover': {
          color: colors.white,
          background: colors.primary.dark,
          boxShadow: `0 0 0 1px ${colors.primary.dark} inset`,
        },

        '&:disabled, &[disabled], &[data-disabled=true]': {
          color: colors.slateGray,
          background: colors.light,
          boxShadow: `0 0 0 1px ${colors.whiteFog} inset`,
          cursor: 'not-allowed',
        },
      },
      outline: {
        color: colors.dark,
        background: colors.white,
        boxShadow: `0 0 0 1px ${colors.whiteFog} inset`,

        '&:hover': {
          background: colors.soft,
        },

        '&:disabled, &[disabled], &[data-disabled=true]': {
          color: colors.slateGray,
          background: colors.light,
          boxShadow: `0 0 0 1px ${colors.whiteFog} inset`,
          cursor: 'not-allowed',
        },
      },
      ghost: {
        color: colors.dark,

        '&:hover': {
          color: colors.primary.dark,
        },

        '&:disabled, &[disabled], &[data-disabled=true]': {
          color: colors.slateGray,
          cursor: 'not-allowed',
        },
      },
      danger: {
        color: colors.white,
        background: colors.error,

        '&:hover': {
          background: '#F0545D',
        },

        '&:disabled, &[disabled], &[data-disabled=true]': {
          color: colors.slateGray,
          background: colors.light,
          boxShadow: `0 0 0 1px ${colors.whiteFog} inset`,
          cursor: 'not-allowed',
        },
      },
      light: {
        color: colors.white,
        // background: colors.,

        '&:hover': {
          // background: '#F0545D',
        },

        '&:disabled, &[disabled], &[data-disabled=true]': {
          color: colors.slateGray,
          background: colors.light,
          boxShadow: `0 0 0 1px ${colors.whiteFog} inset`,
          cursor: 'not-allowed',
        },
      },
    },
  }),
  props =>
    props.$loading && {
      pointerEvents: 'none',
    },
  width
)

Btn.defaultProps = {
  variant: 'primary',
  size: 'default',
}
