import { motion } from 'framer-motion'
import { Button } from 'reakit/Button'
import styled from 'styled-components'
import { variant } from 'styled-system'
import { colors } from '~/styles/colors'
import { fonts } from '~/styles/fonts'

import type { CSSObject } from 'styled-components'
export type Variants = 'info' | 'success' | 'warning' | 'error'

type Props = {
  variant: Variants
}

export const Wrapper = styled(motion.div)<Props>(
  {
    position: 'fixed',
    zIndex: 999,
    left: '50%',
    transform: 'translateX(-50%)',
    bottom: 20,
    fontSize: '0.875rem',
    fontWeight: fonts.text.medium,
    color: colors.white,
    display: 'flex',
    height: 56,
    alignItems: 'center',
    paddingLeft: 20,
    borderRadius: 4,
    whiteSpace: 'nowrap',

    '> svg': {
      marginRight: 18,
    },
  },
  variant<CSSObject, Variants>({
    variants: {
      info: {
        background: colors.dark,
      },
      success: {
        background: colors.success,
      },
      warning: {
        background: colors.warning,
      },
      error: {
        background: colors.error,
      },
    },
  })
)

export const CloseButton = styled(Button)({
  height: 56,
  width: 56,
  flexShrink: 0,
  marginLeft: 20,
  borderLeft: '1px solid rgba(255,255,255,0.2)',
  alignSelf: 'flex-end',
  textAlign: 'center',

  '&:hover': {
    background: 'rgba(255, 255, 255, 0.2)',
  },
})
