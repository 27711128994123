import { useCopyClipboard } from "~/hooks/use-copy-clipboard"
import { Button } from "./button"

type CopyToClipboardButtonProps = {
  // Default label of the button
  label: string
  // Value to be copied to the clipboard
  value: string
}

export const CopyToClipboardButton = (props: CopyToClipboardButtonProps) => {
  const [copyClipboard, hasCopied] = useCopyClipboard()

  return (
    <Button onClick={() => copyClipboard(props.value)}>
      {hasCopied ? 'Copied' : props.label}
    </Button>
  )
}
