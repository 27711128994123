export const colors = {
  primary: {
    default: 'var(--kindest-primary-default)',
    light: 'var(--kindest-primary-light)',
    ultralight: 'var(--kindest-primary-ultralight)',
    dark: 'var(--kindest-primary-dark)',
  },
  dark: 'var(--kindest-dark)',
  slateGray: 'var(--kindest-slate-gray)',
  whiteFog: 'var(--kindest-white-fog)',
  light: 'var(--kindest-light)',
  soft: 'var(--kindest-soft)',
  white: 'var(--kindest-white)',
  success: 'var(--kindest-success)',
  orange: 'var(--kindest-orange)',
  error: 'var(--kindest-error)',
  warning: 'var(--kindest-warning)',
  border: '1px solid var(--kindest-white-fog)',
  chariot: {
    lightBlue: 'var(--chariot-light-blue)',
    darkBlue: 'var(--chariot-darh-blue)',
  },
}
