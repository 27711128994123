
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AnyOrder": [
      "OfflineOrder",
      "OnlineOrder"
    ],
    "AnyTransaction": [
      "BankAccountTransaction",
      "CardTransaction",
      "ChariotTransaction",
      "OfflineTransaction"
    ],
    "Automation": [
      "EmailAutomation",
      "SmsAutomation"
    ],
    "CampaignPost": [
      "FacebookPost",
      "Sms"
    ],
    "OnlineTransaction": [
      "BankAccountTransaction",
      "CardTransaction",
      "ChariotTransaction"
    ],
    "Order": [
      "OfflineOrder",
      "OnlineOrder"
    ],
    "PaymentDetail": [
      "PaymentDetailBankAccount",
      "PaymentDetailCard",
      "PaymentDetailChariot",
      "PaymentDetailOffline"
    ],
    "PayoutMethod": [
      "PayoutBankAccount",
      "PayoutCard"
    ],
    "SegmentCondition": [
      "BooleanSegmentValue",
      "DateSegmentValue",
      "IntSegmentValue",
      "PageSegmentValue",
      "TagSegmentValue"
    ],
    "SoftCredit": [
      "SoftCreditContact",
      "SoftCreditInstitution"
    ],
    "Transaction": [
      "BankAccountTransaction",
      "CardTransaction",
      "ChariotTransaction",
      "OfflineTransaction"
    ]
  }
};
      export default result;
    