import styled, { keyframes } from 'styled-components'

const animation = keyframes`
  0%,
  80%,
  100% {
    transform: scale(0);
    opacity: 0;
  }

  40% {
    transform: scale(1);
    opacity: 1;
  }
`

export const Loader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    display: inline-block;
    width: 4px;
    height: 4px;
    background-color: currentColor;
    border-radius: 100%;
    animation: ${animation} 1.4s infinite ease-in-out both;

    :nth-child(1) {
      animation-delay: -0.32s;
    }

    :nth-child(2) {
      animation-delay: -0.16s;
    }
  }
`
