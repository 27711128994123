import Head from 'next/head'
import { useRouter } from 'next/router'

type Props = {
  title?: string
  description?: string
  image?: string | null
  url?: string
  hideKindest?: boolean
}

export const SEO = (props: Props) => {
  const { hideKindest = false } = props

  const router = useRouter()

  const title = hideKindest
    ? props.title
    : props.title
    ? `${props.title} – Kindest`
    : 'Kindest'
  const description = props.description

  const { url, image } = props

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />

      <meta key="ogtype" property="og:type" content="website" />
      <meta
        key="ogurl"
        property="og:url"
        content={url ?? `https://kindest.com${router.pathname}`}
      />
      <meta key="ogtitle" property="og:title" content={title} />
      <meta
        key="ogdescription"
        property="og:description"
        content={description}
      />
      {image && <meta key="ogimage" property="og:image" content={image} />}

      <meta
        key="twittercard"
        property="twitter:card"
        content="summary_large_image"
      />
      <meta key="twitterurl" property="twitter:url" content={url} />
      <meta key="twittertitle" property="twitter:title" content={title} />
      <meta
        key="twitterdesc"
        property="twitter:description"
        content={description}
      />
      {image && (
        <meta key="twitterimage" property="twitter:image" content={image} />
      )}
    </Head>
  )
}
