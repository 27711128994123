import Cookies from 'js-cookie'
import { AccessType } from '~/types'

const getTokenName = (type: AccessType) => {
  return type === 'dashboard' ? 'token' : 'donor-token'
}

const get = (type: AccessType) => {
  return Cookies.get(getTokenName(type))
}

const set = (type: AccessType, token: string) => {
  Cookies.set(getTokenName(type), token, {
    expires: 30,
    ...(process.env.NODE_ENV === 'production' && {
      secure: true,
      sameSite: 'lax',
    }),
  })
}

const remove = (type: AccessType) => {
  return Cookies.remove(getTokenName(type))
}

export const accessToken = {
  get,
  set,
  remove,
}
