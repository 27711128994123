import { Loader } from './style'

export const Loading = () => {
  return (
    <Loader>
      <div />
      <div />
      <div />
    </Loader>
  )
}
