import { useCallback, useEffect, useState } from 'react';

async function copyToClipboard(text: string) {
  // Navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
      await navigator.clipboard.writeText(text);
  } else {
      // Use the 'out of viewport hidden text area' trick
      const textArea = document.createElement("textarea");
      textArea.setAttribute('readonly', '');
      textArea.value = text;
          
      // Move textarea out of the viewport so it's not visible
      textArea.style.position = "absolute";
      textArea.style.left = "-999999px";
          
      document.body.prepend(textArea);
      textArea.select();

      try {
          document.execCommand('copy');
      } catch (error) {
          console.error(error);
      } finally {
          textArea.remove();
      }
  }
}

export function useCopyClipboard(timeout = 1500) {
  const [hasCopied, setHasCopied] = useState(false)

  const copyClipboard = useCallback((text: string) => {
    copyToClipboard(text);
    setHasCopied(true);
  }, [])

  useEffect(() => {
    if (hasCopied) {
      const id = setTimeout(() => {
        setHasCopied(false)
      }, timeout)

      return () => clearTimeout(id)
    }

    return
  }, [timeout, hasCopied])

  return [copyClipboard, hasCopied] as const
}
